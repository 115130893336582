import {domReady} from '@roots/sage/client';
import Swiper from 'swiper/bundle';
import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import imagesloaded from 'imagesloaded/imagesloaded.pkgd.min';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { jarallax, jarallaxVideo } from 'jarallax';
import reframe from 'reframe.js';
import LazyLoad from 'vanilla-lazyload';
import Rellax from "rellax";

//import Paroller from 'paroller.js/dist/jquery.paroller.js';

/**
 * app.main
 */
const main = async (err) => {
  
  if (err) {
    // handle hmr errors
    console.error(err);
  }

    /////////////////////////
    // application code start
    /////////////////////////

    jarallaxVideo();


    // Look for .hamburger
    var hamburger = document.querySelector(".hamburger");
    // On click
    hamburger.addEventListener("click", function() {
      console.log("menu clicked"); 
      // Toggle class "is-active"
      hamburger.classList.toggle("is-active");
      // Do something else, like open/close menu
    });


    // sticky header
    jQuery(document).ready(function($){
        if($('.site-header.sticky-header').length) {
            var headerHeight = $('.site-header.sticky-header').outerHeight(); // get the header height
            //$('main').css('padding-top', headerHeight); // match the height of the header with the mau
        }
        $(window).scroll(function() {
          //if ($(window).width() > 1199) { // desktop only
            if ($(document).scrollTop() > 50) { // scroll this amount to trigger
              $('.site-header.sticky-header').addClass('shrink'); // add shrink when we are at the top
              setTimeout(function() { // run the same header height matching on scroll
                var headerHeight = $('.site-header.sticky-header').outerHeight(); // get the new shrink height
                //$('main').css('padding-top', headerHeight); // change main padding with the new height
              }, 400);
            } else {
              $('.site-header.sticky-header').removeClass('shrink'); // remove shrink when we are at the top2
            }

          //}
        });
    });

    // scroll-up-header
    jQuery(document).ready(function($){
        if ($(window).width() > 1199) { // desktop only

          if($('.site-header.scroll-up-header').length) {
            var prevScrollpos = window.pageYOffset;
            var headerDiv = document.querySelector(".scroll-up-header"); // Get the header element and it's position
            var headerBottom = headerDiv.offsetTop + headerDiv.offsetHeight;
            var headerHeight = $('.scroll-up-header').outerHeight(); // get the header height
            //$('main').css('padding-top', headerHeight); // match the height of the header with the mau
          
            window.onscroll = function() {
              var currentScrollPos = window.pageYOffset;
              if (prevScrollpos <= currentScrollPos ){  // if scrolling down, let it scroll out of view as normal
                  headerDiv.classList.remove("fixedToTop");
                  //console.log('header height is' headerHeight);
                  headerDiv.style.top = "-" + headerHeight + "px";
              } else {  // otherwise if we're scrolling up, fix the nav to the top
                  headerDiv.classList.add("fixedToTop");
                  headerDiv.style.top = "0";
                  //$('main').css('padding-top', headerHeight); // match the height of the header with the mau
              }
              prevScrollpos = currentScrollPos;
            }
          }

        };
    });

    jQuery(document).ready(function($){ // on page load


      jarallax(document.querySelectorAll(".jarallax"));


      if ($(window).width() > 1199) { // desktop only
        var rellax = new Rellax('.rellax');
      };

      
      var lazyLoadInstance = new LazyLoad({ 
        elements_selector: ".lazy",
      });


      // disable search form until user query is added
      $('form.search-form > input').keyup(function() {
        var empty = false;
        $('form.search-form > input').each(function() {
            if ($(this).val() == '') {
                empty = true;
            }
        });
        if (empty) {
            $('.cs-search').attr('disabled', 'disabled');
        } else {
            $('.cs-search').removeAttr('disabled');
        }
      });


      var cookieName = 'sampleCookie'; // The cookie name
      var cookieLifetime = 10; // Cookie expiry in days
    
      /**
       * Set a cookie
       * @param cname - cookie name
       * @param cvalue - cookie value
       * @param exdays - expiry in days
       */
      var _setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      };
    
      /**
       * Get a cookie
       * @param cname - cookie name
       * @returns string
       */
      var _getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      };
    
      /**
       * Should the cookie popup be shown?
       */
      var _shouldShowPopup = function () {
        if (_getCookie(cookieName)) {
          return false;
        } else {
          return true;
        }
      };
    
      // Show the cookie popup on load if not previously accepted
      if (_shouldShowPopup()) {
        setTimeout(function(){
          $('#samplemodal').modal('show')
        }, 3000);
      }
    
      // Modal dismiss btn - consent
      $('#samplemodal .btn-close').on('click', function () {
        _setCookie(cookieName, 1, cookieLifetime);
      });
      
      $('.cbp-link').click(function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'ShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      });

      if ($("#success-message").hasClass("sib-form-message-panel--active")) {
        $("#sib-container").addClass('hide-me');
      }

      reframe('iframe');

    });

    // Accommodation slider 1
    var swiper = new Swiper(".swiper-accommodation-1-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-1", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-1-button-next",
        prevEl: ".swiper-accommodation-1-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });


    // Accommodation slider 2
    var swiper = new Swiper(".swiper-accommodation-2-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-2", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-2-button-next",
        prevEl: ".swiper-accommodation-2-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });

    // Accommodation slider 3
    var swiper = new Swiper(".swiper-accommodation-3-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-3", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-3-button-next",
        prevEl: ".swiper-accommodation-3-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });

    // Accommodation slider 4
    var swiper = new Swiper(".swiper-accommodation-4-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-4", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-4-button-next",
        prevEl: ".swiper-accommodation-4-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });

    // Accommodation slider 5
    var swiper = new Swiper(".swiper-accommodation-5-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-5", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-5-button-next",
        prevEl: ".swiper-accommodation-5-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });

    // Accommodation slider 6
    var swiper = new Swiper(".swiper-accommodation-6-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-6", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-6-button-next",
        prevEl: ".swiper-accommodation-6-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });

    // Accommodation slider 7
    var swiper = new Swiper(".swiper-accommodation-7-thumbs", {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    var swiper2 = new Swiper(".swiper-accommodation-7", {
      loop: false,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-accommodation-7-button-next",
        prevEl: ".swiper-accommodation-7-button-prev",
      },
      thumbs: {
        swiper: swiper,
      },
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      effect: "fade",
    });


    // init Swiper:
    const swiperbannersplit = new Swiper('.swiper-banner-split', {
      // Optional parameters
      loop: true,
      // If we need pagination
      pagination: {
        el: '.swiper-banner-split-pagination',
        clickable: true
      },
    });


    // initialise tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  

    // var typed = new Typed('.typed', {
    //   strings: ["health.^900", "well-being.^900", "emotional support.^900", "hormonal balance.^900", "gut health.^900", "skin care.^900", "energy.^900", "sleep.^900", "relaxation.^900", "life."],
    //   typeSpeed: 60,
    //   loop: true,
    //   loopCount: Infinity,
    //   backSpeed: 30
    // });


    // $(document).ready(function(){
    //     $('.hero-cta-height').matchHeight();
    // });
    // $(window).resize(function() {
    //     $('.hero-cta-height').matchHeight();
    // });
  

    // init Swiper:
    const swiperposts = new Swiper('.swiper-posts', {
      // Optional parameters
      loop: false,
      breakpoints: {
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      // If we need pagination
      pagination: {
        el: '.swiper-posts-pagination',
        clickable: true
      },
    });



    // init Swiper:
    const swiperslidergallery = new Swiper('.swiper-slider-gallery', {
      // Optional parameters
      loop: true,
      slidesPerView: 1.4,
      spaceBetween: 20,
      centeredSlides: true,
      breakpoints: {
        576: {
          slidesPerView: 2.4,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.4,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3.4,
          spaceBetween: 20,
        },
      },
      // If we need pagination
      pagination: {
        el: '.swiper-slider-gallery-pagination',
        clickable: true
      },
    });


    // init Swiper:
    const swipertestimonialsv1 = new Swiper('.swiper-testimonials-v1', {
      // Optional parameters
      loop: true,
      autoplay: {
         delay: 4000,
      },
      // If we need pagination
      pagination: {
        el: '.swiper-testimonials-v1-pagination',
        clickable: true
      },
    });


    // init Swiper:
    const swipertestimonialsv2 = new Swiper('.swiper-testimonials-v2', {
      // Optional parameters
      loop: true,
      autoplay: {
         delay: 4000,
      },
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
      // If we need pagination
      pagination: {
        el: '.swiper-testimonials-v2-pagination',
        clickable: true
      },
    });


    // init Swiper:
    const swipertestimonialsv3 = new Swiper('.swiper-testimonials-v3', {
      // Optional parameters
      loop: true,
      autoplay: {
         delay: 4000,
      },
      // If we need pagination
      pagination: {
        el: '.swiper-testimonials-v3-pagination',
        clickable: true
      },
    });


    // isotope for galleries
    jQuery(document).ready(function($){

        var imagesLoaded = require('imagesloaded');
        (function ($) {
            var $grid = $('.grid').imagesLoaded( function() {
              $('.grid').isotope({
                // options
                itemSelector: '.grid-item',
                layoutMode: 'masonry'
              });
            });
            // filter items on button click
            $('.filter-button-group').on( 'click', 'button', function() {
              var filterValue = $(this).attr('data-filter');
              $('.grid').isotope({ filter: filterValue });
              
              $('.filter-button-group button').removeClass("active");
              $(this).addClass("active");
            });
        })(jQuery);


        // quick search usage guide
        var qsRegex;

        // init Isotope
        var $grid = $('.uses-grid').isotope({
          itemSelector: '.grid-item',
          layoutMode: 'fitRows',
          filter: function() {
            return qsRegex ? $(this).text().match( qsRegex ) : true;
          }
        });

        // use value of search field to filter
        var $quicksearch = $('.quicksearch').keyup( debounce( function() {
          qsRegex = new RegExp( $quicksearch.val(), 'gi' );
          $grid.isotope();
        }, 200 ) );

        // debounce so filtering doesn't happen every millisecond
        function debounce( fn, threshold ) {
          var timeout;
          threshold = threshold || 100;
          return function debounced() {
            clearTimeout( timeout );
            var args = arguments;
            var _this = this;
            function delayed() {
              fn.apply( _this, args );
            }
            timeout = setTimeout( delayed, threshold );
          };
        }

    });


    
    // initialise photoswipe gallery
    const lightbox = new PhotoSwipeLightbox({
      gallery: '.pswp-gallery',
      children: 'a',
      showHideAnimationType: 'zoom',
      pswpModule: () => import('photoswipe')
    });
    lightbox.init();

    


  


};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
